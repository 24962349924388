import React, { useEffect, useState } from "react";
import { useIntercom } from "react-use-intercom";
import { get } from "../utils/fetch";
import { useRouter } from "next/router";
import { route } from "next/dist/server/router";

export default function Intercom({}) {
  const { boot, hide } = useIntercom();
  const router = useRouter();
  useEffect(() => {
    async function LOL() {
      const P = await get("intercom");
      if (router.pathname != "/messages") boot(P);
      else hide();
    }
    LOL();
  }, [router]);
  return <React.Fragment />;
}
