import axios from "axios";

export async function get(endpoint, data = {}, headers = {}, token) {
  const response = await axios.get(`${process.env.NEXT_PUBLIC_API}/${endpoint}`, {
    params: data,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
    },
  });
  return response.data;
}

export async function post(endpoint, data = {}, headers = {}, token) {
  const response = await axios.post(`${process.env.NEXT_PUBLIC_API}/${endpoint}`, data, {
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
    },
  });
  return response.data;
}

export async function postFiles(endpoint, formData) {
  const response = await axios.post(`${process.env.NEXT_PUBLIC_API}/${endpoint}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    withCredentials: true,
  });
  return response.data;
}
