import { get, post } from "../utils/fetch";
import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import { HiOutlineCheckCircle } from "react-icons/hi";
import Spinner from "../components/spinner";

import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import Intercom from "../components/intercom";

function Login(props) {
  const router = useRouter();
  const { x, y } = router.query;
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("init");
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [verify, setVerify] = useState(false);
  const [magic, setMagic] = useState(true);

  const init = () => {
    setError("");
    setMessage("");
    setShow(false);
    setVerify(false);
    setStatus("init");
  };

  useEffect(() => {
    const check = async function () {
      const response = await get("is_logged_in");
      if (response.creator_id != "guest") {
        router.push(`/home`);
      }
    };
    check();
  }, []);

  useEffect(() => {
    const auth = async function () {
      const response = await post(`auth/magic/verify`, {
        x,
        y,
      });
      if (response.status == "success") {
        router.push(`/home`);
      } else {
        setMessage("");
        setError(response.message);
        setVerify(false);
      }
    };
    if (x && y) {
      setVerify(true);
      auth();
    }
  }, [x, y]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [F, setF] = useState(false);

  return (
    <div className="flex flex-col w-full m-4 p-6 space-y-6 bg-white sm:p-8 sm:w-[380px] border border-gray-50">
      <Head>
        <title>{F ? "Forgot Password" : "Login"} | Fuego</title>
        <meta name="viewport" content="width=device-width, user-scalable=no" />
      </Head>
      <Intercom />

      <img src="/logo.svg" className="object-contain w-auto h-7" />

      {!F && (
        <>
          {verify && (
            <div className="flex flex-col items-center justify-center space-y-3">
              <Spinner />
              <span className="text-sm font-medium">Authenticating</span>
            </div>
          )}
          {!verify && (
            <>
              {message && <div className="message-card"> {message} </div>}

              {error && <div className="error-card"> {error} </div>}

              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  setStatus("load");
                  const response = await post(`auth/${magic ? "magic" : "login"}`, {
                    email,
                    password,
                  });
                  if (response.status == "success") {
                    if (magic) {
                      setError("");
                      setMessage(`We've sent a magic link to ${email}. Please click on it to sign in.`);
                      setStatus("done");
                    } else {
                      router.push("/home");
                    }
                  } else {
                    setMessage("");
                    setError(response.message);
                    setStatus("init");
                  }
                }}
                className="flex flex-col w-full space-y-1"
              >
                <div className="relative w-full pb-2">
                  <input
                    type="email"
                    className="float-input"
                    placeholder="hello@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <span className="float-label bg-white">Email</span>
                </div>
                {!magic && (
                  <>
                    <div className="relative w-full">
                      <input
                        id="password"
                        name="password"
                        type={show ? "text" : "password"}
                        autoComplete="current-password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="float-input"
                      />
                      <button
                        type="button"
                        id="toggle"
                        onClick={() => setShow(show ^ 1)}
                        className="right-3 top-[1.10rem] absolute w-5 h-5"
                      >
                        {show ? <EyeOffIcon /> : <EyeIcon />}
                      </button>
                      <span className="float-label bg-white">Password</span>
                    </div>
                    <button onClick={() => setF(true)} className="ml-auto text-xs text-blue-700 py-1" type="button">
                      Forgot Password?
                    </button>
                  </>
                )}
                <button className={"btn " + (magic ? "btn-cyan" : "btn-teal")} type="submit">
                  {status == "init" && <span>{magic ? "Sign in with email" : "Continue"}</span>}
                  {status == "load" && <Spinner />}
                  {status == "load" && <span>Processing</span>}
                  {status == "done" && (
                    <span className="text-base">
                      <HiOutlineCheckCircle />
                    </span>
                  )}
                  {status == "done" && <span>Magic Link Sent</span>}
                </button>
                {magic && (
                  <>
                    <div className="text-xs text-gray-500 -my-1 text-center mx-auto py-1">OR</div>

                    <button
                      className="btn btn-teal"
                      type="button"
                      onClick={() => {
                        init();
                        setMagic(false);
                      }}
                    >
                      Sign in manually
                    </button>
                  </>
                )}

                <div className="flex items-center justify-center space-x-1 text-center pt-4">
                  <span>Not Registered?</span>
                  <a
                    href="https://form.typeform.com/to/JKFghTty?typeform-source=www.fuego.ai"
                    className="text-orange-700 font-medium"
                  >
                    Get early access
                  </a>
                </div>
              </form>
            </>
          )}
        </>
      )}
      {F && (
        <>
          {message && <div className="message-card"> {message} </div>}
          {error && <div className="error-card"> {error} </div>}
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              setStatus("load");
              const response = await post(`auth/forgot`, {
                email,
              });
              if (response.status == "success") {
                setError("");
                setMessage(`We've sent a temporary password to ${email}.`);
                setStatus("done");
              } else {
                setMessage("");
                setError(response.message);
                setStatus("init");
              }
            }}
            className="flex flex-col w-full space-y-1"
          >
            <div className="relative w-full pb-2">
              <input
                type="email"
                className="float-input"
                placeholder="hello@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <span className="float-label bg-white">Email</span>
            </div>
            <button className={"btn " + "btn-teal"} type="submit">
              {status == "init" && <span>Get temporary password</span>}
              {status == "load" && <Spinner />}
              {status == "load" && <span>Processing</span>}
              {status == "done" && (
                <span className="text-base">
                  <HiOutlineCheckCircle />
                </span>
              )}
              {status == "done" && <span>Check your email</span>}
            </button>
            <div className="flex items-center justify-center space-x-1 text-center pt-4">
              <span>Remembered your password?</span>
              <button
                onClick={() => {
                  init();
                  setMagic(false);
                  setF(false);
                }}
                className="text-blue-700 font-medium"
              >
                Login Now
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
}

export default function Store() {
  return (
    <div className="flex items-center justify-center h-screen md:bg-gray-25">
      <Login />
    </div>
  );
}
